import image1 from "../assets/home-frames/frame-005.png";
import image2 from "../assets/home-frames/frame-006.png";
import image3 from "../assets/home-frames/frame-007.png";
import image4 from "../assets/home-frames/frame-008.png";
import image5 from "../assets/home-frames/frame-009.png";

const images = [
    image1,
    image2,
    image3,
    image4,
    image5
]

export default images;