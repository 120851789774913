import React, { useRef, useState, useEffect } from "react";
import Layout from "../components/Layout";
import { window } from "browser-monads";
import styled from "styled-components";
import { mobileAndDown } from "../utils/styleUtils";
import currentFrame from "../utils/earn_images";
import { gsap, ScrollTrigger } from "gsap/all";
import TextLoop from "react-text-loop";
import {
    PhoneContainer,
    PhoneImage,
    TipStepText,
    TipSpan,
    Title,
    TipTextContainer,
} from "../components/UiComponents";
import Faq from "../components/Faq";

gsap.registerPlugin(ScrollTrigger);

const Earn = ({ data }) => {
    const imageRef = useRef(null);
    const text1Ref = useRef(null);
    const text2Ref = useRef(null);
    const text3Ref = useRef(null);
    const text4Ref = useRef(null);
    const text5Ref = useRef(null);
    const text6Ref = useRef(null);

    const [chart1Height, setChart1Height] = useState(null);
    const [chart2Height, setChart2Height] = useState(null);
    const [chart3Height, setChart3Height] = useState(null);
    const [chart4Height, setChart4Height] = useState(null);
    const [chart5Height, setChart5Height] = useState(null);
    const [chart6Height, setChart6Height] = useState(null);
    const [innerHeight, setInnerHeight] = useState(null);
    const [loaded, setLoaded] = useState(null);

    useEffect(() => {
        if (!loaded && (text1Ref !== null || text1Ref !== undefined)) {
            setChart1Height(text1Ref.current.offsetHeight);
            setChart2Height(text2Ref.current.offsetHeight);
            setChart3Height(text3Ref.current.offsetHeight);
            setChart4Height(text4Ref.current.offsetHeight);
            setChart5Height(text5Ref.current.offsetHeight);
            setChart6Height(text6Ref.current.offsetHeight);
            setLoaded(true);
        }
    });

    useEffect(() => {}, [chart6Height]);

    useEffect(() => {
        setInnerHeight(window.innerHeight);
    }, [window.innerHeight]);

    useEffect(() => {
        if (loaded) {
            ScrollTrigger.create({
                trigger: "#phone",
                endTrigger: "#text6",
                start: "center center",
                end: () => {
                    console.log("chart5Height", chart6Height);
                    console.log("chart5Height", chart6Height);
                    return `bottom ${
                        chart6Height + (innerHeight - chart6Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            ScrollTrigger.create({
                trigger: "#text1",
                endTrigger: "#text2",
                start: "top top",
                end: () => {
                    return `bottom ${
                        chart1Height + (innerHeight - chart1Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            ScrollTrigger.create({
                trigger: "#text2",
                endTrigger: "#text3",
                start: "top top",
                end: () => {
                    return `bottom ${
                        chart2Height + (innerHeight - chart2Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            ScrollTrigger.create({
                trigger: "#text3",
                endTrigger: "#text4",
                start: "top top",
                end: () => {
                    return `bottom ${
                        chart3Height + (innerHeight - chart3Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            ScrollTrigger.create({
                trigger: "#text4",
                endTrigger: "#text5",
                start: "top top",
                end: () => {
                    return `bottom ${
                        chart4Height + (innerHeight - chart4Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            ScrollTrigger.create({
                trigger: "#text5",
                endTrigger: "#text6",
                start: "top top",
                end: () => {
                    return `bottom ${
                        chart5Height + (innerHeight - chart5Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            gsap.utils.toArray(".steps").forEach((step, index) => {
                ScrollTrigger.create({
                    trigger: step,
                    start: "top top",
                    end: "bottom top",
                    toggleClass: "active",
                    onEnter: () => {
                        // document.getElementById('phone').src=currentFrame[index]
                        imageRef !== null && imageRef.current !== null
                            ? (imageRef.current.src = currentFrame[index])
                            : void 0;
                    },
                    onLeave: () => {
                        // document.getElementById('phone').src=currentFrame[index]
                        imageRef !== null && imageRef.current !== null
                            ? (imageRef.current.src = currentFrame[index])
                            : void 0;
                    },
                    onEnterBack: () => {
                        // document.getElementById('phone').src=currentFrame[index]
                        imageRef !== null && imageRef.current !== null
                            ? (imageRef.current.src = currentFrame[index])
                            : void 0;
                    },
                    onLeaveBack: () => {
                        // document.getElementById('phone') !== null ?
                        // document.getElementById('phone').src=currentFrame[index]: void(0)
                        imageRef !== null && imageRef.current !== null
                            ? (imageRef.current.src = currentFrame[index])
                            : void 0;
                    },
                });
            });
        }
    }, [chart5Height]);

    return (
        <Layout navColor="#131313" background="#131313">
            <Container>
                <div id="title">
                    <Title>
                        Create Tips, <br />
                        Answer Questions, <br />
                        <TextLoop>
                            <span>
                                Earn <TipSpan>Royalties.</TipSpan>
                            </span>
                            <span>
                                Generate <TipSpan>Income.</TipSpan>
                            </span>
                            <span>
                                Showcase your <br />
                                <TipSpan>Expertise.</TipSpan>
                            </span>
                            <span>
                                Build a <TipSpan>Community.</TipSpan>
                            </span>
                        </TextLoop>
                    </Title>
                </div>
                <PhoneContainer id="phone">
                    <PhoneImage src={currentFrame[0]} ref={imageRef} />
                </PhoneContainer>
                <TipTextContainer>
                    <TipStepText className="steps" id="text1" ref={text1Ref}>
                        Share your knowledge of iOS by creating your own Tips
                    </TipStepText>
                    <TipStepText className="steps" id="text2" ref={text2Ref}>
                        Tip creation is simple - follow our step-by-step
                        instuctions
                    </TipStepText>
                    <TipStepText className="steps" id="text3" ref={text3Ref}>
                        Apple's iOS technologies + your code + explanation =
                        success
                    </TipStepText>
                    <TipStepText className="steps" id="text4" ref={text4Ref}>
                        Answer questions about your Tips at a price you set
                    </TipStepText>
                    <TipStepText className="steps" id="text5" ref={text5Ref}>
                        Showcase your expertise and grow your community
                    </TipStepText>
                    <TipStepText id="text6" ref={text6Ref}></TipStepText>
                </TipTextContainer>
                <Title>FAQ</Title>
                <Faq
                    title="How can I make money with ZipTips?"
                    content="
                        There are two ways you can make money on ZipTips.  One way is to create a popular Tip. Each month, our top three tips earn $250 each.  Another way to make money is to answer a question about your Tip.  You can set your own price for answering a question.
                    "
                />
                <Faq
                    title="How much should I charge to answer questions about my Tip?"
                    content="
                        That decision is yours. You can choose from $19.99, $29.99, $39.99, $59.99, $79.99 and $99.99 (USD). Remember, Apple gets 30% of this price and ZipTips takes a $4.50 processing fee.
                    "
                />
                <Faq
                    title="Why should I create content for ZipTips instead of YouTube or Stack Overflow?"
                    content="
                        ZipTips isn't designed to replace those outlets. Instead, ZipTips offers another great way for you to share your knowledge and earn money. Create a Tip featuring an iOS technology and when developers have questions, you can help them and make money in the process, rewarding your time and effort.
                    "
                />
                <Faq
                    title="Do I need to be a subscriber to view a Tip?"
                    content="
                        No. Anyone can view a tip. A subscription to ZipTips is only required to download a Tip’s
                        Swift Playground or to ask a creator a question.
                    "
                />
                <Faq
                    title="How will I know if my Tip is one of the top three Tips of the month?"
                    content="
                        On the first day of each month, we will post the top three Tips and their creators on ZipTips.io, and will notify each winning creator by e-mail.  The top three Tips are determined by the number of unique Swift Playground downloads by paid subscribers.
                    "
                />
                <Faq
                    title="My Tip was one of the top three Tips of the month. How will I get paid?"
                    content="
                        Congratulations! 😃 In our notification e-mail, we will provide details on how to receive payment. We currently only support Stripe Connect for payments which is available in 35+ countries across 14 languages.
                    "
                />
            </Container>
        </Layout>
    );
};

const Container = styled.div`
    min-height: 100vh;
    padding: 1rem 5rem;
    ${mobileAndDown`
        padding: 1rem 2rem;
    `}
`;

export default Earn;
