import React, { useState, useRef } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components";
  import { mobileAndDown } from "../utils/styleUtils";

function Faq(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon__rotate"
    );
  }

  return (
    <AccordionSection active={setActive}>
        <Accordion onClick={toggleAccordion}>
            <FAQHeader>{props.title}</FAQHeader>
            <FontAwesomeIcon icon={faChevronDown} className={setRotate}/>
        </Accordion>
        <AccordionContent
            ref={content}
            style={{maxHeight: `${setHeight}`}}>
                <DescriptionText>
                    {props.content}
                </DescriptionText>
        </AccordionContent>
    </AccordionSection>
  );
}

const FAQHeader = styled.h2`
    display: inline-block;
    color: white;
`

const AccordionSection = styled.div`
    display: flex;
    flex-direction: column;
    &::after {
      content: '';
      display: block;
      height: 2px;
      margin-top: 10px;
      margin-left: 5px;
      background: ${props => props.active === 'active' ? '#3B9AF8' : 'linear-gradient(to right, #3B9AF8 75%, #3E3E3E 25%)'};
      background-size: 200% 100%;
      background-position: right bottom;
      transition: all 2s ease-out;
    }
    &:hover::after {
        background-position: left bottom;
    }
    width: 65%;
    ${mobileAndDown`
      width: 100%;
    `}
`

const Accordion = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    text-align: left;
    color: white;
    & .accordion__icon {
      margin-left: 20px;
      transition: transform 0.6s ease;
    }
    & .accordion__icon__rotate {
      margin-left: 20px;
      transition: transform 0.6s ease;
      transform: rotate(-180deg);
    }
`

const AccordionContent = styled.div`
    background-color: transparent;
    font-family: Circular-Book;
    color: #BBBBBB;
    overflow: auto;
    transition: max-height 0.6s ease;
    padding: 0 8px;
    overflow-y: hidden;
`

const DescriptionText = styled.p`
  ${mobileAndDown`
    width: 100%;
  `}
`

export default Faq;
